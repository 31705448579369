import React from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "@reach/router";
import { graphql } from "gatsby";

import Docs from "../components/docs";
import { auth } from "../utils/auth";
import isDev from "../utils/isDev";

import "../styles/prism-dracula.scss";
import "../styles/style.scss";

export default function ApiDocumentation(props) {
  const docsData = props.data.markdownRemark;

  if (!auth.isAuthenticated()) {
    if (!isDev) {
      return <Redirect noThrow to="/auth" />;
    }

    window.location.href = "/auth";

    return;
  }

  return (
    <>
      <Helmet
        title={docsData.frontmatter.title}
        meta={[
          { name: "description", content: docsData.frontmatter.title },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Docs docs={docsData} />
    </>
  );
}

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { ne: "docs/index.md" }) {
      html
      htmlAst
      frontmatter {
        language_tabs
        title
        footer
        search
        attachments {
          publicURL
        }
      }
    }
  }
`;
